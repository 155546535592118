<template>
  <div class="record">
    <div class="record-top">{{ $t("planet.desc53") }}</div>
    <div class="table-con">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column :label="$t('planet.desc54')" width="170">
          <template #default="scope">
            <img :src="scope.row.landIcon" alt="" />
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNum"
          :label="$t('planet.desc55')"
          width="150"
        >
          <template #default="scope">
            #{{ scope.row.eraId }}-{{ scope.row.planetId }}
          </template>
        </el-table-column>
        <el-table-column
          prop="toAddress"
          :label="$t('planet.desc56')"
          width="240"
        >
          <template #default="scope">
            <div class="copy-info">
              <div>{{ scope.row.toAddress1 }}</div>
              <img
                src="../../public/images/planetcopy.png"
                alt=""
                @click="copy($event, scope.row.toAddress)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="fromAddress"
          :label="$t('planet.desc57')"
          width="240"
        >
          <template #default="scope">
            <div class="copy-info">
              <div>{{ scope.row.fromAddress1 }}</div>
              <img
                src="../../public/images/planetcopy.png"
                alt=""
                @click="copy($event, scope.row.fromAddress)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fromUserId" :label="$t('planet.desc58')" />
        <el-table-column prop="toUserId" :label="$t('planet.desc59')" />
        <el-table-column
          prop="createTime"
          :label="$t('planet.desc60')"
          width="180"
        />
      </el-table>
    </div>
    <div class="table-mobile-con">
      <div
        class="table-info-list"
        v-for="(item, index) in tableData"
        :key="item.id"
      >
        <div class="info-list-item flex">
          <img :src="item.landIcon" alt="" class="list-item-img" />
          <div class="item-info flex">
            <div class="item-info-name">
              <div>{{ $t("planet.desc43") }}</div>
              <p>#{{ item.eraId }}-{{ item.planetId }}</p>
            </div>
            <div class="item-info-name">
              <div>{{ $t("planet.desc60") }}</div>
              <p>{{ item.createTime }}</p>
            </div>
            <div
              class="item-info-more"
              :class="{ actimg: item.ischeck }"
              @click="handlerMore(index)"
            >
              <span v-if="!item.ischeck">{{ $t("planet.desc64") }}</span>
              <span v-else>{{ $t("planet.desc65") }}</span>
              <img src="../../public/images/mobiledown.png" alt="" />
            </div>
          </div>
        </div>
        <div class="info-list-more flex" v-if="item.ischeck">
          <div class="more-info">
            <div>{{ $t("planet.desc58") }}</div>
            <p>{{ item.fromUserId }}</p>
          </div>
          <div class="more-info">
            <div>{{ $t("planet.desc59") }}</div>
            <p>{{ item.toUserId }}</p>
          </div>
          <div class="more-info">
            <div>{{ $t("planet.desc56") }}</div>
            <p>{{ item.toAddress1 }}</p>
          </div>
          <div class="more-info">
            <div>{{ $t("planet.desc57") }}</div>
            <p>{{ item.fromAddress1 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="planet-page">
      <el-pagination
        layout="prev, pager, next"
        :pager-count="5"
        :page-size="10"
        :current-page="page"
        :total="totals"
        @current-change="handlerCurrent"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import clipboard from "@/utils/libs/clipboard";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      totals: 0,
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    handlerCurrent(val) {
      this.page = val;
      this.getlist();
    },
    copy(e, text) {
      clipboard(e, text);
      this.$message.success(this.$t("planet.desc61"));
    },
    getlist() {
      this.$post(this.URL.planet.record, {
        page: 1,
        pageSize: 10,
      }).then((res) => {
        if (res.code == 0) {
          res.data.list.forEach((e) => {
            e.toAddress1 =
              e.toAddress.substring(0, 4) +
              "......" +
              e.toAddress.substring(e.toAddress.length - 4, e.toAddress.length);
            e.fromAddress1 =
              e.fromAddress.substring(0, 4) +
              "......" +
              e.fromAddress.substring(
                e.fromAddress.length - 4,
                e.fromAddress.length
              );
            e.ischeck = false;
          });
          // this.tableData = res.data.list;
          this.totals = parseFloat(res.data.page.count);
        } else if (res.code != 401) {
          this.$message.error(res.message);
        }
      });
    },
    handlerMore(i) {
      if (!this.tableData[i].ischeck) {
        this.tableData.forEach((e) => {
          e.ischeck = false;
        });
        this.tableData[i].ischeck = true;
      } else {
        this.tableData[i].ischeck = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  width: 1240px;
  padding: 136px 0 0;
  margin: 0 auto;

  .record-top {
    font-size: 24px;
    color: #ffffff;

    img {
      display: none;
    }
  }

  .table-con {
    margin-top: 40px;

    :deep(.el-table--border .el-table__inner-wrapper::after),
    :deep(.el-table--border::after),
    :deep(.el-table--border::before),
    :deep(.el-table__inner-wrapper::before) {
      border-bottom: none;
      background: transparent;
    }

    :deep(.el-table .cell) {
      padding: 0;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 1);
    }

    :deep(.el-table__row) {
      background: #000;
      cursor: pointer;
    }

    :deep(
        .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell
      ) {
      background: #0e0e0e;
    }

    :deep(.el-table .el-table__cell) {
      // background: #000;
      height: 64px;
      border-bottom: 1px solid rgba(255, 226, 127, 0.3);

      &:first-child {
        padding-left: 24px;

        img {
          width: 54px;
          height: 54px;
          object-fit: cover;
        }
      }

      &:last-child {
        padding-right: 24px;
      }

      .copy-info {
        display: flex;

        img {
          flex: 0 0 12px;
          width: 12px;
          height: 12px;
          margin-left: 8px;
        }
      }
    }
    :deep(.el-table) {
      background: transparent;
    }
    :deep(.el-table tr) {
      background: #000;
      border-radius: 8px;
    }
    :deep(.el-table th.el-table__cell) {
      background: rgba(255, 226, 127, 0.3);
      height: 64px;
      border-bottom: none;
    }
    :deep(.el-table__header-wrapper) {
      border-radius: 8px;
      overflow: hidden;
    }

    :deep(.el-table th.el-table__cell .cell) {
      font-family: "PingFang SC";
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .table-mobile-con {
    display: none;
  }

  .planet-page {
    padding: 40px 0;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #ffe279 !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .record {
    width: auto;
    padding: 68px 15px 0;

    .record-top {
      position: relative;
      font-size: 16px;
      padding: 12px 0;
    }

    .table-con {
      display: none;
    }

    .table-mobile-con {
      display: block;

      .table-info-list {
        margin-bottom: 10px;
        padding: 16px 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;

        .info-list-item {
          .list-item-img {
            flex: 0 0 60px;
            width: 60px;
            height: 60px;
            margin-right: 20px;
            object-fit: cover;
          }

          .item-info {
            flex: 1;
            flex-wrap: wrap;

            .item-info-name {
              flex: 0 0 50%;
              font-size: 12px;
              color: #a8a8a8;

              p {
                margin-top: 8px;
                color: #ffffff;
              }
            }

            .item-info-more {
              margin-top: 10px;
              flex: 0 0 100%;
              font-size: 12px;
              color: #a8a8a8;
              display: flex;

              img {
                width: 6px;
                height: 5px;
                margin: 7px 0 0 4px;
                transform: rotate(180deg);
                transition: 0.5s;
              }
            }
            .actimg {
              img {
                transform: rotate(0);
                transition: 0.5s;
              }
            }
          }
        }

        .info-list-more {
          margin-top: 20px;

          .more-info {
            flex: 0 0 20%;
            font-size: 12px;
            color: #a8a8a8;

            &:nth-last-child(-n + 2) {
              flex: 0 0 30%;
            }

            p {
              margin-top: 8px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
